import { createApp } from "vue/dist/vue.esm-bundler.js";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import { Swiper, SwiperSlide } from "swiper/vue";

import "virtual:svg-icons-register";
import "@/assets/styles/styles.scss";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log("HMR");
	});
}

const app = createApp({});

const components = import.meta.glob("./components/**/*", {
	eager: true,
});

Object.entries(components).forEach(([path, definition]) => {
	const componentName = upperFirst(
		camelCase(
			path
				.split("/")
				.pop()
				.replace(/\.\w+$/, "")
		)
	);

	// Register component on this Vue instance
	app.component(componentName, definition.default);
});

app.component("swiper-slide", SwiperSlide);

app.mount("#app");
